import { ParsedUrlQuery } from 'querystring';
import { getPageByDataTypeAlias, getSiteSettings, umbraco } from '../../lib/api';
import { getVehicle, getVehicleFamily } from '../../lib/api/vehicle/hessel-vehicle-pdp-api';
import { mapProductDetailsList } from '../../lib/mappers/vehicle/product-details.mapper';
import { matchBattery } from '../../lib/state/hessel-site/product-details/state-update.helper';
import { hesselViewModels } from '../../lib/view-models';
import { GetServerSidePropsResult } from 'next';
import { OwnershipTab, PhyronData, ProductDetails } from '../../lib/view-models/vehicle';
import { CarColor } from '../../lib/api/models/hessel-api/vehicle';
import { ContentSpotSettings } from '../../lib/api/models/umbraco/content-spot';
import { convertGlobalSpotItems } from './spot.helpers';

export const vehicleName = (makeId: string, model: string, variant: string): string => {
    return `${makeId} ${model} ${variant}`;
};

export function getVehicleFamilyId(carUniqueId: string): string {
    const splitVehicleFamilyId = carUniqueId.split('-');
    const [vehicleType, availability, familyId] = splitVehicleFamilyId;
    return `${vehicleType}-${availability}-${familyId}`;
}

export function getVehicleAvailability(familyId: string): hesselViewModels.VehicleAvailability {
    const [, availability] = familyId.split('-');
    return availability.toLowerCase() === 'o' ? 'Order' : 'InStock';
}

export function getCarSsrQueryColor(vehicle: hesselViewModels.ProductDetails, colorFromQuery?: string): hesselViewModels.ProductColor {
    const color = vehicle.colors?.find((x) => x.name.toLowerCase() === colorFromQuery || x.code === vehicle.color);
    return color ?? vehicle.colors[0] ?? null;
}

/**
 * Since vehicles from PLP have no longer color as part of their URL, no we can differentiate between links to PDP.
 * If Query color is not available we want to do as we do today and return the cheapest color as standard.
 * If query color is available we want to return that color if it exists in the vehicle colors.
 * @param vehicle
 * @param queryColor
 * @returns
 */
export function getCheapestCarSsrColor(
    vehicle: hesselViewModels.ProductDetails,
    queryColor: string | undefined | null
): hesselViewModels.ProductColor {
    if (vehicle.availability === 'Order') {
        if (queryColor) {
            const color = vehicle.colors?.find((x) => x.name.toLowerCase() === queryColor.toLowerCase());
            return color ?? vehicle.colors[0];
        }

        // Find the minimum cash price color
        const minCashPrice = Math.min(...(vehicle.colors?.map((color) => color.cashPrice ?? 0) || [0]));

        // Filter colors by the minimum cash price
        const cheapestColors = vehicle.colors?.filter((color) => color.cashPrice === minCashPrice);

        // Filter images by type 'image'
        const images: Array<hesselViewModels.Image> =
            (vehicle.resources.filter((resource) => resource.type === 'image') as Array<hesselViewModels.Image>) || [];

        // Filter cheapestColors by looking into the images array and pick the first one that has a resource
        // that matches the color code.
        const cheapestColorsWithImages = cheapestColors?.filter((color) => {
            const image = images.find((image) => image.colorCode === color.code);
            return image !== undefined;
        });

        if (cheapestColorsWithImages && cheapestColorsWithImages.length > 0) {
            // At this point we know we don't have query color
            const matchedVehicleColor = vehicle.colors?.find((x) => x.code === vehicle.color);

            if (!matchedVehicleColor) {
                return cheapestColorsWithImages[0];
            }

            // Try returning the color from the query if it exists in the cheapest colors with images
            const existingStandardColor = cheapestColorsWithImages.find((x) => x.id === matchedVehicleColor.id);
            if (existingStandardColor) {
                return existingStandardColor;
            }

            return cheapestColorsWithImages[0];
        }

        // Try to find a color from vehicle colors whose price matches the minimum cash price.
        const matchedVehicleColorByMinPrice = vehicle.colors?.find((x) => x.cashPrice === minCashPrice);
        if (matchedVehicleColorByMinPrice) {
            return matchedVehicleColorByMinPrice;
        }

        return vehicle.colors[0];
    }

    if (vehicle.availability === 'Used' || vehicle.availability === 'Engros') {
        return {
            id: vehicle.color,
            name: vehicle.color,
            resource: {
                id: '',
                name: '',
                data: '',
                type: '',
                filename: '',
            },
            code: vehicle.color,
        };
    }
    return vehicle.colors?.[0];
}

export function getCarType(params: string): string {
    const paramsArray = params.split('-');
    return paramsArray[paramsArray.length - 1];
}

export const familyAndConfigurationsBuilder = async (
    params: ParsedUrlQuery | undefined,
    query: ParsedUrlQuery
): Promise<{
    currentVehicle: hesselViewModels.ProductDetails;
    carFamily: hesselViewModels.ProductDetails[];
    allConfigurations: hesselViewModels.CarConfiguration[];
    page: umbraco.Page;
    siteSettings: umbraco.SiteSettings;
    errorMessage?: string;
}> => {
    const errorGuidePrefix = `[EJH-SPA]-hessel-[getStaticProps]`;

    if (!params) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}- no params available.`,
        };
    }

    let slug = '';
    if (params.slug) {
        if (Array.isArray(params.slug)) {
            slug += params.slug.join('/');
        } else {
            slug += params.slug;
        }
    }
    const splitSlug = slug.split('/');
    const carUniqueId = splitSlug[splitSlug.length - 1];
    const vehicleFamilyId = getVehicleFamilyId(carUniqueId);
    const vehicleAvailability = getVehicleAvailability(vehicleFamilyId);

    const [[pages, pageError], [siteSettings, siteSettingsError], [fetchedVehicleFamily, fetchedVehicleFamilyError]] = await Promise.all([
        getPageByDataTypeAlias('productDetailsPage'),
        getSiteSettings(),
        vehicleAvailability === 'Order' ? getVehicleFamily(vehicleFamilyId) : getVehicle(carUniqueId),
    ]);

    if (siteSettingsError) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}-[getSiteSettings]: ${siteSettingsError.errorType} - ${siteSettingsError.statusCode}`,
        };
    }

    if (pageError) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}-[getPage]: ${pageError.errorType} - ${pageError.statusCode}`,
        };
    }

    if (!siteSettings) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}- getSiteSettings returned no data`,
        };
    }

    if (!pages || pages.length < 1) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}- getPage returned no data`,
        };
    }

    if (fetchedVehicleFamilyError) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}-[getVehicleFamily]: ${fetchedVehicleFamilyError.errorType} - ${fetchedVehicleFamilyError.statusCode}`,
        };
    }

    if (!fetchedVehicleFamily || !Array.isArray(fetchedVehicleFamily) || fetchedVehicleFamily.length === 0) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}- getVehicleFamily returned no data`,
        };
    }

    const mappedVehicleFamily = mapProductDetailsList(fetchedVehicleFamily);

    const allConfigurations = fetchedVehicleFamily
        .filter((x) => !x.discontinued)
        .reduce(
            (acc, car) => {
                return {
                    carTypes: [...new Set([...acc.carTypes, car.bodyType])],
                    fuelTypes: [...new Set([...acc.fuelTypes, car.specification.fuelType])],
                    transmissionTypes: [...new Set([...acc.transmissionTypes, car.specification.transmissionType])],
                    tractionWheels: [...new Set([...acc.tractionWheels, car.specification.tractionWheels])],
                    lengthTypes: [...new Set([...acc.lengthTypes, car.specification.lengthType])],
                    heightTypes: [...new Set([...acc.heightTypes, car.specification.heightType])],

                    equipmentLines:
                        car.brand.toLowerCase() === 'ford' ||
                        car.brand.toLowerCase() === 'renault' ||
                        car.brand.toLowerCase() === 'dacia' ||
                        car.brand.toLowerCase() === 'mercedes-benz'
                            ? car.equipmentLine && car.equipmentLine.length > 0
                                ? [...new Set([...acc.equipmentLines, car.equipmentLine])]
                                : [...new Set([...acc.equipmentLines])]
                            : [],

                    batteryInfoList: !acc.batteryInfoList.some((x) => x.capacity === car.specification.batteryCapacity)
                        ? [
                              ...acc.batteryInfoList,
                              {
                                  capacity: car.specification.batteryCapacity ?? '',
                                  range: car.specification.range ?? -1,
                              },
                          ]
                        : [...acc.batteryInfoList],
                };
            },
            {
                carTypes: [],
                fuelTypes: [],
                transmissionTypes: [],
                tractionWheels: [],
                lengthTypes: [],
                heightTypes: [],
                equipmentLines: [],
                batteryInfoList: [],
            } as hesselViewModels.CarConfiguration
        );

    const currentVehicle = mappedVehicleFamily?.find((x) => x.id === carUniqueId);
    if (!currentVehicle) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}- currentVehicle not found`,
        };
    }

    const color = query.color as string;

    const vehicleHasRequestedColor =
        currentVehicle.availability === 'Order'
            ? currentVehicle.colors.some((x) => x.name.toLowerCase() === color?.toLowerCase())
            : currentVehicle.colors.length > 0 || currentVehicle.availability === 'Used' || currentVehicle.availability === 'Engros';

    if (!vehicleHasRequestedColor && (!currentVehicle.colors || currentVehicle.colors.length === 0)) {
        return {
            page: {} as umbraco.Page,
            siteSettings: {} as umbraco.SiteSettings,
            currentVehicle: {} as hesselViewModels.ProductDetails,
            carFamily: [],
            allConfigurations: [],
            errorMessage: `${errorGuidePrefix}- currentVehicle does not have requested color as resources`,
        };
    }

    const page = pages[0] as umbraco.ProductDetailsPage;

    return {
        page: { ...page, contentSpotsSettings: convertGlobalContentSpot(page.contentSpotsSettings) },
        siteSettings,
        currentVehicle,
        carFamily: mappedVehicleFamily?.filter((x) => !x.discontinued) ?? [],
        allConfigurations: [allConfigurations],
    };
};

export const isEquipmentLineValid = (
    carFamily: Array<hesselViewModels.ProductDetails>,
    equipmentLine: string,
    selectedTab: hesselViewModels.OwnershipTab,
    selectedProduct: hesselViewModels.ProductDetails | undefined
): boolean => {
    if (!selectedProduct) return false;
    const qualifiedVehicles = carFamily.filter((x) => {
        if ((selectedTab === 'Car Finansiering' || selectedTab === 'Van Finansiering') && !x.purchaseTypes.financing.show) return false;
        if (selectedTab === 'Car HiRE' && !x.purchaseTypes.hire.show) return false;
        if ((selectedTab === 'Car Kontant' || selectedTab === 'Van Kontant') && !x.purchaseTypes.cash.show) return false;
        if (selectedTab === 'Beskatningsgrundlag' && !x.purchaseTypes.solutionTaxation.show) return false;
        if (selectedTab === 'Car Privat Leasing' && !x.purchaseTypes.privateLeasing.show) return false;
        if (selectedTab === 'Finansiel Leasing' && !x.purchaseTypes.financialLeasing.show) return false;
        if (selectedTab === 'Operationel Leasing' && !x.purchaseTypes.operationalLeasing.show) return false;
        return (
            x.equipmentLine === equipmentLine &&
            x.carType === selectedProduct.carType &&
            x.fuelType === selectedProduct.fuelType &&
            x.transmissionType === selectedProduct.transmissionType &&
            x.tractionWheels === selectedProduct.tractionWheels &&
            (x.vehicleType === 'Van' ? x.lengthType === selectedProduct.lengthType && x.heightType === selectedProduct.heightType : true) &&
            (x.fuelType === 'El'
                ? matchBattery(x.batteryCapacity ?? '', x.range ?? 0, selectedProduct.batteryCapacity ?? '', selectedProduct.range ?? 0)
                : true)
        );
    });
    if (qualifiedVehicles && qualifiedVehicles.length > 0) return true;
    return false;
};

export const handleDiscontinuedVehicles = (
    currentVehicle: hesselViewModels.ProductDetails,
    carFamily: hesselViewModels.ProductDetails[]
): GetServerSidePropsResult<never> => {
    const matchingVehicle = carFamily.find(
        (x) =>
            x.carType === currentVehicle.carType &&
            x.tractionWheels === currentVehicle.tractionWheels &&
            x.transmissionType === currentVehicle.transmissionType &&
            x.fuelType === currentVehicle.fuelType &&
            x.lengthType === currentVehicle.lengthType &&
            x.heightType === currentVehicle.heightType
    );
    if (matchingVehicle) {
        return {
            redirect: {
                destination: matchingVehicle.url,
                statusCode: 301,
            },
        };
    }
    return {
        notFound: true,
    };
};

export const evaluateNewColor = (
    newSelection: ProductDetails | undefined,
    selectedColor: CarColor | undefined,
    ownershipTab: OwnershipTab | undefined
): CarColor | undefined => {
    if (newSelection === undefined || selectedColor === undefined || ownershipTab === undefined) return undefined;
    const newColor = newSelection?.colors.find((y) => y.id === selectedColor?.id);
    if (
        newColor &&
        (((ownershipTab === 'Car Kontant' ||
            ownershipTab === 'Beskatningsgrundlag' ||
            ownershipTab === 'Van Kontant' ||
            ownershipTab === 'Car Finansiering' ||
            ownershipTab === 'Van Finansiering') &&
            newColor.cashPrice === selectedColor?.cashPrice) ||
            (ownershipTab === 'Car HiRE' && newColor.monthlyHirePrice === selectedColor.monthlyHirePrice) ||
            (ownershipTab === 'Car Privat Leasing' && newColor.monthlyPrivateLeasingPrice === selectedColor.monthlyPrivateLeasingPrice) ||
            (ownershipTab === 'Finansiel Leasing' && newColor.monthlyFinancialLeasingPrice === selectedColor.monthlyFinancialLeasingPrice) ||
            (ownershipTab === 'Operationel Leasing' && newColor.monthlyOperationalLeasingPrice === selectedColor.monthlyOperationalLeasingPrice))
    ) {
        return newColor;
    }
    const cheapest = newSelection?.colors.find((x) => x.cashPrice === 0);
    return cheapest;
};

export const hasPhyronContent = async (currentVehicle: hesselViewModels.ProductDetails): Promise<PhyronData | null> => {
    if (currentVehicle.availability !== 'Used') return null;

    try {
        const dataCampaign = buildPhyronDataCampaign(currentVehicle.brand);
        const referenceNumber = currentVehicle.referenceNumber?.toUpperCase();

        // Check if the video exists without downloading it
        const abortController = new AbortController();
        setTimeout(() => abortController.abort(), 1000);
        const response = await fetch(`https://${dataCampaign}.cust.nl.phyron.com/repo/${referenceNumber}/1024x768.mp4`, {
            method: 'HEAD',
            signal: abortController.signal,
        });

        return response.ok ? { dataCampaign, referenceNumber } : null;
    } catch (error) {
        console.log('Error calling phyron', error);
        return null;
    }
};
const buildPhyronDataCampaign = (brand: string): string => {
    let dataCampaign = 'ejnerhessel';
    if (brand === 'Renault' || brand === 'Dacia') {
        dataCampaign += '-dacia-renault';
    } else {
        dataCampaign += brand === 'Ford' ? '-ford' : '';
    }
    return dataCampaign;
};

const convertGlobalContentSpot = (contentSpotsSettings: ContentSpotSettings[]) => {
    return contentSpotsSettings.map((spot: ContentSpotSettings) => {
        let spotData = spot;
        if (spot?.alias === 'globalContentSpot') {
            spotData = {
                ...spot.contentSpot.selectedContentSpot?.[0],
            };
        }
        return convertGlobalSpotItems(spotData);
    });
};
